<template>
  <div :class="props.outerWrapperClasses">
    <div :class="props.innerWrapperClasses">
      <div
        v-if="props.row?.fields?.content?.length"
        ref="contentWrap"
        :class="[
          props.rowEntryWrapperClasses,
          props.row.fields.classes ? props.row.fields.classes : '',
        ]"
      >
        <template
          v-for="(entry, index) in props.row.fields.content"
          :key="index"
        >
          <ModularRowContentEntries :row="props.row" :entry="entry" />
        </template>
      </div>
    </div>
    <div v-if="props.isVideo" class="hero--video">
      <LazyVideosTheVideo
        v-if="props.row?.fields?.backgroundImage"
        :video="props.row.fields.backgroundImage.fields"
      />
    </div>
    <LinksContentfulLink
      v-if="props.row.sys.id"
      :id="props.row.sys.id"
      label="row"
    />
  </div>
</template>

<script setup>
  const props = defineProps({
    row: {
      type: Object,
      required: true,
    },
    isVideo: {
      type: Boolean,
      required: false,
      default: false,
    },
    innerWrapperClasses: {
      type: String,
      required: false,
      default: '',
    },
    outerWrapperClasses: {
      type: String,
      required: false,
      default: '',
    },
    rowEntryWrapperClasses: {
      type: String,
      required: false,
      default: '',
    },
  });
</script>
